@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .modal-backdrop.show {
    opacity: 0.6;
  }
  .popover-body:hover {
    background: #253147;
    & *:not(.not-change) {
      svg {
        path {
          fill: #3391ff;
        }
      }
      div {
        color: #3391ff;
      }
    }
  }
  .popover .arrow {
    display: none;
  }

  .custom-control-label {
    &:before,
    &:after {
      top: 0;
    }
  }
}

.tickers-list,
.tickers-list-1 {
  will-change: auto !important;
}

div {
  font-size: 14px;
  font-weight: 400;
  -ms-overflow-style: none;
}

button {
  border: none;
}

::-webkit-scrollbar {
  // background: transparent;
  display: block;
  width: 0px;
  height: 6px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(94, 102, 115);
}

.modal-dialog {
  &[class^='modal-'] {
    .modal-content {
      border-radius: 8px;
    }
  }
}

.modal-1200 {
  max-width: 1200px;
  width: 1200px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
  }
}

.modal-890 {
  max-width: 890px;
  width: 890px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
  }
}

.modal-1100 {
  max-width: 1100px;
  width: 1100px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
    border: 0px;
  }
}

.modal-900 {
  max-width: 900px;
  width: 900px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
    border: 0px;
  }
}

.modal-704 {
  max-width: 704px;
  width: 704px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
  }
}

.modal-668 {
  max-width: 662px;
  width: 90vw;
  .modal-content {
    background-color: transparent;
  }
}

.modal-600 {
  max-width: 600px;
  width: 600px;
  .modal-content {
    border-radius: 4px;
    background-color: transparent;
  }
}

.modal-596 {
  max-width: 596px;
  width: 596px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
  }
}

.modal-584 {
  max-width: 584px;
  width: 584px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
  }
}

.modal-590 {
  max-width: 590px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
  }
}

.modal-463 {
  max-width: 463px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
  }
}

.modal-500 {
  max-width: 500px;
  width: 500px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
  }
}

.modal-550 {
  max-width: 550px;
  width: 550px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
  }
}

.modal-468 {
  max-width: 468px;
  width: 468px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
    border: 0px;
  }
}

.modal-461 {
  max-width: 461px;
  width: 461px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
  }
}

.modal-450 {
  max-width: 450px;
  width: 450px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
  }
}

.modalPdf {
  max-width: 450px;
  width: 450px;
  max-height: 620px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
  }
}

.modal-437 {
  max-width: 437px;
  width: 437px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
  }
}

.modal-434 {
  max-width: 434px;
  width: 434px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
  }
}

.modal-416 {
  max-width: 416px;
  width: 416px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
    border: 0px;
  }
  position: absolute;
  right: 20px;
  top: 10px;
}

.modal-300 {
  max-width: 300px;
  width: 300px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
  }
}

.modal-284 {
  max-width: 284px;
  width: 284px;
  .modal-content {
    border-radius: 8px;
    background-color: transparent;
  }
}

.infinite-scroll-component__outerdiv {
  height: 100%;
  .infinite-scroll-component {
    thead {
      tr > th {
        top: 0px;
        position: sticky;
      }
    }
  }
}

.cursor-pointer {
  user-select: none;
  cursor: pointer;
}

.mr-12 {
  margin-right: 12px;
}

.ant-modal-header {
  margin-bottom: 20px !important;
}
.ant-modal-title {
  font-size: 18px !important;
}

.popupSwal {
  .swal2-html-container {
    font-size: 14px;
  }
  .titleSwal {
    font-size: 20px;
  }
}

.ant-modal-confirm-content {
  max-width: 100% !important;
}

.ant-form-item-required {
  position: relative;
  &::after {
    right: -10px;
  }
  &::before {
    position: absolute;
    margin-inline-end: -10px !important;
    right: 0;
  }
}
.ant-form-horizontal {
  .ant-form-item-required {
    margin-right: 12px;
  }
  ::before {
    right: 13px;
    top: 7px;
  }

}

.ant-picker-input{
  input{
    color: #2d394b !important
  }
}

.ant-typography-disabled{
  color: #919191 !important;
}

.quickSearchOverlay{
  .ant-tooltip-inner{
    max-width: 500px;
    text-align: justify;
  }

  .ant-tooltip-content{
    min-width: max-content;
  }
}

.ant-tooltip-inner{
  white-space: pre-wrap;
}

.common-cascader-dropdown{

  .ant-cascader-menu-item-content{
    -webkit-line-clamp:1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
}
